import React, { useState } from 'react';
import person from './Assets/person.png';
import axios from "axios";
import { useNavigate, useLocation } from 'react-router-dom';
import { getFirestore, addDoc, collection, setDoc, doc, updateDoc, getDoc, getDocs, query, where } from 'firebase/firestore';

const EmailSubmission = () => {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const db = getFirestore();
    const [responseWaiting, setResponseWaiting] = useState(false);


    const handleInputChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = async () => {

        const docRef = doc(db, "InterviewQA", email);
        const docSnap = await getDoc(docRef);
        let positionValue, resumeValue, criteriasValue, jobdescValue, metricsValue;

        if (docSnap.exists()) {
            const docData = docSnap.data();
            positionValue = docData.Position;
            resumeValue = docData.Resume
            const positionsCollectionRef = collection(db, "Positions");
            const querySnapshot = await getDocs(query(positionsCollectionRef, where("Position", "==", positionValue)));
            if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {

                    criteriasValue = doc.data().Criterias
                    jobdescValue = doc.data().JobDesc
                    metricsValue = doc.data().Metrics

                });
            } else {
                console.log("No documents found with the position value:", positionValue);
            }
        } else {
            console.log("Document does not exist for email:", email);
        }
        setResponseWaiting(true);
        try {
            const response = await axios.post(
                "https://hirexweb.onrender.com/full_interview",
                {
                    prompt__:
                        `I will give you informations about a job posting and the candidate that will be passing the interview, and I want you to give me questions to qsk the candidqte based on all the information I will give you about him and the job posting. 
                \n\n I - Job Position: \n - ${positionValue} 
                \n\n II - Job Description: \n - ${jobdescValue}
                \n\n III - Interview targeted Metrics: \n - ${metricsValue}
                \n\n IV - Interview targeted Criterias: \n - ${criteriasValue}
                \n\n V - Candidate's resume: \n - ${resumeValue}
                \n\n VI - Format of response: \n - I want the response to this prompt to be exactly like this => "1-... , 2-... , 3-..."
                
                \n\n\n\n It is extremely important to be as quick as possible in responding and to respect the given guidelines, especially the one about the format response.
                `,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            //console.log("Response / " + response.data.text);


            const questions = response.data.text.split(/\d+-/).filter(question => question.trim() !== "");
            const trimmedQuestions = questions.map(question => question.trim());

            console.log(trimmedQuestions)

            navigate(
                '/recordingfeedback', { state: { email: email, questions: trimmedQuestions } }
            );


        } catch (error) {
            console.log(error);
        }
        setResponseWaiting(false);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div className="input">
                <img src={person} alt='' />
                <input type='text' placeholder='Identifier' value={email} onChange={handleInputChange} />
                <div style={{ display: "flex", gap: "30px", margin: "300px" }}>
                    <div className={"submit gray"} onClick={handleSubmit}>Submit</div>
                </div>
            </div>
            {responseWaiting && (
                <div className="loading-overlay">
                    <div className="loading-spinner"></div>
                </div>
            )}
        </div>
    );
}

export default EmailSubmission;
