// Dashboard.js

import React, { useState, useEffect } from "react";
import './Dashboard.css';
import {  useNavigate  } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import "../firebase";
import { collection, getFirestore, onSnapshot } from "firebase/firestore";
import next from './Assets/next.png'

import plus from './Assets/plus.png'

function PerformanceDashboard() {
  const [position, setPosition] = useState("");
  const [numOfPositions, setNumOfPositions] = useState("")
  const [companyNames, setCompanyNames] = useState("");
  const db = getFirestore()
   const navigate = useNavigate();
 
 


  let num;

useEffect(() => {
  const unsubscribe = onSnapshot(collection(db, "InterviewQA"), (snapshot) => {
    num = snapshot.docs.length;
    setNumOfPositions(num);

    const names = snapshot.docs.map((doc) => doc.data()["placeholder"]);
      setCompanyNames(names);


  });

  // Cleanup function
  return () => {
    unsubscribe();
  };
}, []); // Empty dependency array signifies that this effect runs only once


console.log(numOfPositions);



  return (
    <div className="dashboard-container">
      <div className="header">
         {/* <img
          src="https://i.ibb.co/zPTC1c8/HIreX.png"
          alt="Logo"
          className="logo"
        /> */}
         <div className="user-info">
          <img
            src="https://cdn4.iconfinder.com/data/icons/avatars-21/512/avatar-circle-human-female-4-512.png"
            alt="User"
            className="user-image"
          />
          <div className="user-details">
            <h1>Fatima al-Fihri</h1>
            <p>HR Specialist</p>
          </div>
        </div>
      </div>

      <div className='submit-container'>
          <div >
            
          </div>
      </div>




      <div className="positions-wrapper">



      {
  [...Array(numOfPositions).keys()].map((index) => (
    <div key={index} className="position-item">
      <div className="position-details">
        <h3>{companyNames[index]}</h3>
      </div>
      <div className='submit-next'>
          <div className={"submit"}  onClick={()=>{
        //     navigate(
        //     '/detail',{state:{position_selected:companyNames[index]}}
        // );
         }} >  
           <img src={next} alt='' />
          </div>
      </div>
    </div>
  ))
}




      </div>
    </div>
  );
}

export default PerformanceDashboard;
