import React, { useState, useEffect } from "react";
import axios from "axios";
import './Triage.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faArrowUpFromBracket, faSearch, faGreaterThan, faMousePointer } from "@fortawesome/free-solid-svg-icons";
import { getFirestore, addDoc, collection, setDoc, doc, updateDoc } from 'firebase/firestore';


const Triage = () => {
  const db = getFirestore();
  const [extractedTexts, setExtractedTexts] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [acceptances, setAcceptances] = useState([]);
  const [loaders, setLoaders] = useState([]);
  // const inpFileRef = React.createRef();
  const location = useLocation();
  const navigate = useNavigate();
  const position = location.state.position
  const jd = location.state.jd
  const criterias = location.state.criterias
  const metrics = location.state.metrics
  const threshold = location.state.threshold
  const [percentage, setPercentage] = useState(0);
  const [totalNumFiles, setTotalNumFiles] = useState(0);
  const [filteredExtractedTexts, setFilteredExtractedTexts] = useState([]);
  const [filteredFileNames, setFilteredFileNames] = useState([]);
  const [filteredAcceptances, setFilteredAcceptances] = useState([]);
  const [downsized, setDownsized] = useState(false)


  /* useEffect(() => {
    console.log("Acceptances changed:", acceptances);
  }, [acceptances]);*/

  const handleUpload = (event) => {
    event.target.classList.add('clicked');
    setFileNames([])
    setAcceptances([])
    setExtractedTexts([])
    setPercentage(0)
    const inpFile = inpFileRef.current.files;
    setTotalNumFiles(inpFile.length)
    for (let i = 0; i < inpFile.length; i++) {
      const formData = new FormData();
      formData.append("pdfFile", inpFile[i]);

      fetch("https://pdfreader-15w0.onrender.com/extract-text", {
        method: "post",
        body: formData,
      })
        .then((response) => response.text())
        //  .then((text) => setExtractedTexts());
        .then((text) => {
          setFileNames((name) => [...name, inpFile[i].name]);
          setExtractedTexts((prevTexts) => [...prevTexts, text.trim()]);

        });
    }
  };

  async function GetAccept(event) {
    setAcceptances([])
    setPercentage(0)
    event.target.classList.add('clicked');
    for (let j = 0; j < extractedTexts.length; j++) {

      console.log("Prompt :" + `I will give you the cv of candidate applying for a ${position} position, you give me a comment on whether he matches or not the criteria I will give you below based on his CV. The response should be a percentage [for eg : (4 criterias matched)/(8 criterias) you respond 50 to express as 50%]  \n\n ` +
        ` Criteria : \n ${criterias}` +
        //  `The response should be in this format:  Criteria: \n Format: \n\n` +
        //   `also add a percentage (number of criteria matched)/ (total number of criteria) at the end\n\n` +
        `Candidate CV: \n` +
        extractedTexts[j],
      )

      try {
        const response = await axios.post(
          "https://hirexweb.onrender.com/full_interview",
          {
            prompt__:
              `I will give you the cv of candidate applying for a ${position} position, you give me a comment on whether he matches or not the criterias I will give you below based on his CV, and be perspicacious in reading the cvs and criterias. The response should be a percentage [for eg : (4 criterias matched)/(8 criterias) you respond 50 to express as 50%], then I want the response to be only digits (for eg : 40), no details required  \n\n ` +
              `\n\n\n\n I/ Criteria : \n\n ${criterias}` +
              //  `The response should be in this format:  Criteria: \n Format: \n\n` +
              //   `also add a percentage (number of criteria matched)/ (total number of criteria) at the end\n\n` +
              `\n\n\n\n II/ Candidate CV: \n\n` +
              extractedTexts[j] +
              `\n\n\n\n III/ Response format : \n\n The response should be strictly a number between 0 and 100`,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("YO / " + response.data.text);
        setAcceptances((t) => [...t, response.data.text]);

        if ((j + 1) > 2) {
          setPercentage(((j + 1) % 2) / totalNumFiles);
        } else {
          setPercentage((j + 1) / totalNumFiles);
        }
      } catch (error) {
        console.log(error);
      }
    }


  };

  const candidates = [
    {
      name: 'Candidate 1',
      description: 'Lorem ipsum dolor sit amet, consectetur adipism ipsum dolor sit amet, consectetur adipism ipsum dolor sit amet, consectetur adipism ipsum dolor sit amet, consectetur adipism ipsum dolor sit amet, consectetur adipiscing elit',
    },
    {
      name: 'Candidate 2',
      description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
    },
    {
      name: 'Candidate 3',
      description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
    },
    {
      name: 'Candidate 4',
      description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
    },
    {
      name: 'Candidate 5',
      description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
    }
  ];

  const CandidateItem = ({ index, transcription }) => {




    return (
      <div className="candidate-item">
        <div className="candidate-name">
          {fileNames[index]}
        </div>
        <div className="candidate-description">
          {/* <p>{transcription}</p> */}
          <p>{extractedTexts[index]}</p>
        </div>
        <div className="candidate-description">
          <p>{acceptances[index]}</p>
        </div>
        {/* <div className="circle">
          <b>{percentage}%</b>
        </div> */}
        <div class="checkbox-container">
          <label class="checkbox-label">
            <input type="checkbox" class="checkbox-input" />
            <span class="checkmark">
            </span>
          </label>
        </div>
        <div className="candidate-navigate">
          <FontAwesomeIcon icon={faGreaterThan} />
        </div>
      </div>
    );
  };



  // const [progressValue, setProgressValue] = useState(100); Initialize progress bar with a default value
  const inpFileRef = React.useRef(null);
  const handleButtonClick = (event) => {
    event.target.classList.add('clicked');
    inpFileRef.current.click();
  };
  return (
    <div className="containerr">

      <div className="toolbar">
        {/* <input type="file" id="inpFile" ref={inpFileRef} multiple /> */}

        <input type="file" id="inpFile" ref={inpFileRef} multiple style={{ display: 'none' }} />
        <button className="button uploadButton" onClick={handleButtonClick}>
          <FontAwesomeIcon icon={faMousePointer} style={{ marginRight: "10px", fontSize: "20px", pointerEvents: "none" }} />
          Choose File
        </button>

        <button className="button uploadButton" onClick={handleUpload}>
          <FontAwesomeIcon icon={faArrowUpFromBracket} style={{ marginRight: "10px", fontSize: "20px", pointerEvents: "none" }} />
          Upload Files
        </button>
        <button className="button evaluateButton" onClick={GetAccept}>
          <FontAwesomeIcon icon={faSearch} style={{ marginRight: "10px", fontSize: "20px", pointerEvents: "none" }} />
          Evaluate
        </button>
      </div>

      {/* <div className="pbar">
      <div className="progress-bar-container">
        <div className="progress-bar" style={{ width: `${percentage*100}%` }}></div>
      </div>
      <p> {percentage*100}%</p> 
    </div> */}

      <div className="candidate-list">
        {/* {candidates.map((candidate) => (
          <CandidateItem
            key={candidate.id}
            candidate={candidate}
          />
        ))} */}
        {extractedTexts.map((text, index) => (
          <CandidateItem
            index={index}
          // transcription={text}
          // candidate={candidate}
          />
        ))}
      </div>
      {/*<div className="notify-button-container">
         <button className="notify-button">
          Notify Selected Candidates
        </button> */}

      <div className='submit-container'>
        {/* <div className={"submit gray"} onClick={()=>{
                        navigate("/performance")
                        }}>Candidates</div> */}
        <div className={"submit"} onClick={async () => {

          const filteredExtractedTexts = [];
          const filteredFileNames = [];
          const filteredAcceptances = [];
          for (var i = 0; i < acceptances.length; i++) {
            if (acceptances[i] > threshold) {
              filteredExtractedTexts.push(extractedTexts[i]);
              filteredFileNames.push(fileNames[i]);
              filteredAcceptances.push(acceptances[i]);


              await setDoc(doc(db, "InterviewQA", fileNames[i]), {
                "placeholder": fileNames[i],
                "Resume": extractedTexts[i],
                "Position": position
              });

            }
          }

          setFilteredExtractedTexts(filteredExtractedTexts);
          setFilteredFileNames(filteredFileNames);
          setFilteredAcceptances(filteredAcceptances);
          setDownsized(!downsized)








        }}>Save eligible cand.</div>



      </div>
      {
        downsized &&
        <div className='submit-container'>
          <style>
            {`
      .centered-table {
        width: 100%;
        border-collapse: collapse;
      }

      .centered-table th,
      .centered-table td {
        text-align: center;
        padding: 8px;
        border: 1px solid #ddd;
      }
    `}
          </style>

          <table className='centered-table'>
            <thead>
              <tr>
                <th>Identifier</th>
                <th>Acceptance Rate</th>
              </tr>
            </thead>
            <tbody>
              {filteredFileNames.map((fileName, index) => (
                <tr key={index}>
                  <td>{fileName}</td>
                  <td>{filteredAcceptances[index]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

      }


      {/* </div> */}
    </div>
  );
};

export default Triage;
