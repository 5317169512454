import React, { useState, useEffect } from 'react';
import './PositionDetail.css';
import '../index.css';
import "../firebase";
import { useNavigate } from 'react-router-dom';
import { getFirestore, addDoc, collection, onSnapshot } from 'firebase/firestore';
import { useLocation } from 'react-router-dom';
import thresh from './Assets/thresh.png'
import listcheck from './Assets/listcheck.png'
import edit from './Assets/edit.png'
import useradd from './Assets/useradd.png'
import metrics_ from './Assets/metrics.png'

const PositionDetail = () => {
    const db = getFirestore();
    const location = useLocation();
    const navigate = useNavigate();

    const position_selected = location.state.position_selected
    console.log(position_selected)

    const [position, setPosition] = useState("");


    const [jd, setJd] = useState("");



    const [criterias, setCriterias] = useState("");



    const [metrics, setMetrics] = useState("");



    const [threshold, setThreshold] = useState("");


    useEffect(() => {
        onSnapshot(collection(db, "Positions"), (snapshot) => {
            snapshot.docs.forEach((doc) => {
                const positionData = doc.data();
                if (positionData.Position === position_selected) {
                    setPosition(positionData.Position);
                    setJd(positionData.JobDesc);
                    setCriterias(positionData.Criterias);
                    setMetrics(positionData.Metrics);
                    setThreshold(positionData.Threshold);
                    return;
                }
            });
        });
    }, []);




    return (
        <div className='container'>
            <div className="login-header">
                <div className="text">Position details</div>
                <div className="underline"></div>
            </div>
            <div className="inputs">

                <div className="input">
                    <img src={useradd} alt='' />
                    <h3>{position} </h3>
                </div>

                <div className="input">
                    <img src={edit} alt='' />
                    <h3>{(jd).slice(0, 40)} </h3>
                </div>
                <div className="input">
                    <img src={listcheck} alt='' />
                    <h3> {(criterias).slice(0, 40)}</h3>

                </div>
                <div className="input">
                    <img src={metrics_} alt='' />
                    <h3>{(metrics).slice(0, 40)} </h3>
                </div>
                <div className="input">
                    <img src={thresh} alt='' />
                    <h3>{(threshold)} </h3>
                </div>

            </div>





            <div className='submit-container'>
                <div className={"submit gray"} onClick={() => {
                    navigate("/performance")
                }}>Candidates</div>
                <div className={"submit"} onClick={() => {
                    navigate(
                        '/triage', { state: { position: position, jd: jd, criterias: criterias, metrics: metrics, threshold: threshold } }
                    );
                }}>Proceed to Triage</div>
            </div>
        </div>
    )
}

export default PositionDetail