import React, { useState, useEffect } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import "./RecordingPage.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getFirestore, addDoc, collection, setDoc,doc, updateDoc } from 'firebase/firestore';

const RecordingPage = ({
  onequestion,
  qindex,
  setQindex,
  email,
  jD,
  cv,
  bg,
  companyName,
  language,
}) => {
  /// Variables
  const windowHeight = window.innerHeight;
  const [isSelected, setIsSelected] = useState(-1);
  const [isRecording, setIsRecording] = useState(false);
  const { status, startRecording, stopRecording, mediaBlobUrl } =
    useReactMediaRecorder({ audio: true });
  const [listAudios, setListAudios] = useState([]);
  const [isFeedbackTime, setIsFeedbackTime] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [responseWaiting, setResponseWaiting] = useState(false);
  const [transcription, setTranscription] = useState("");
  const db = getFirestore();

  /// Functions
  const toggleRecording = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
    setIsRecording(!isRecording);
  };
  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  const notify = (msg) => {
    toast(msg);
  };
  //Handling of media URL
  const blobToBase64 = async (url) => {
    return new Promise(async (resolve, _) => {
      const blob = await fetch(url).then((r) => r.blob());

      // instantiate a file reader
      const fileReader = new FileReader();

      // read the file
      fileReader.readAsDataURL(blob);

      fileReader.onloadend = () => {
        resolve(fileReader.result); // Here is the base64 string
      };
    });
  };

  /// Hooks
  useEffect(() => {
    if (status === "stopped" && mediaBlobUrl) {
      setListAudios((prevList) => [...prevList, mediaBlobUrl]);
      //setBase64_(blobToBase64(mediaBlobUrl));
    }
  }, [status, mediaBlobUrl]);

  /// Return
  return (
    <div>
      
          <div className={"div3"}>
            <h1 style={{color:"white"}}>{onequestion}</h1>
          </div>
          <div className={"div1"}>
            <div></div>
            <div></div>
            <button
              className={`record-button ${isRecording ? "recording" : ""}`}
              onClick={toggleRecording}
            >
              <i
                className={`fa ${isRecording ? "fa-stop" : "fa-microphone"}`}
              ></i>
            </button>
            {/* <button
              onClick={() => {
                if (isSelected !== -1) {
                  (async () => {
                    const t = await blobToBase64(listAudios[isSelected]);
                    setResponseWaiting(true);
                    try {
                      const response = await axios.post(
                        "https://hirexweb.onrender.com/lng_audio",
                        {
                          audio_data: t.replace("data:audio/wav;base64,", ""),
                        },
                        {
                          headers: {
                            "Content-Type": "application/json",
                          },
                        }
                      );
                      setTranscription(response.data.text);
                      await delay(100);
                      console.log(response.data.text);

                      await updateDoc(doc(db, "InterviewQA", email), {
                        ['Q' + qindex]: onequestion,
                        ['A' + qindex]: response.data.text,
                       });

                       await delay(5);

                      setFeedbackText("");
                      setListAudios([]);
                      setIsSelected(-1);
                      setQindex(qindex + 1);
                       
                    } catch (error) {
                      console.log(error);
                    }

                    setResponseWaiting(false);
                    console.log(transcription);
                    notify("Response saved! Press Get Feedback to proceed.");
                  })();
                } else {
                  console.log("test");
                  notify("Please select an audio first!");
                }
              }}
            >
             Proceed
            </button> */}
                     <div style={{"display": "flex","justify-content": "center", "align-items": "center", "width": "120px","height": "59px","color": "#fff","background": "#3c009d","border-radius": "50px","font-size": "19px","font-weight": "700px","cursor": "pointer"}} onClick={() => {
                if (isSelected !== -1) {
                  (async () => {
                    const t = await blobToBase64(listAudios[isSelected]);
                    setResponseWaiting(true);
                    try {
                      const response = await axios.post(
                        "https://hirexweb.onrender.com/lng_audio",
                        {
                          audio_data: t.replace("data:audio/wav;base64,", ""),
                        },
                        {
                          headers: {
                            "Content-Type": "application/json",
                          },
                        }
                      );
                      setTranscription(response.data.text);
                      await delay(100);
                      console.log(response.data.text);

                      await updateDoc(doc(db, "InterviewQA", email), {
                        ['Q' + qindex]: onequestion,
                        ['A' + qindex]: response.data.text,
                       });

                       await delay(5);

                      setFeedbackText("");
                      setListAudios([]);
                      setIsSelected(-1);
                      setQindex(qindex + 1);
                       
                    } catch (error) {
                      console.log(error);
                    }

                    setResponseWaiting(false);
                    console.log(transcription);
                    notify("Response saved!");
                  })();
                } else {
                  console.log("test");
                  notify("Please select an audio first!");
                }
              }}>Proceed</div>
             
          {/* <button
              onClick={
                
                async () => {
                setResponseWaiting(true);
                try {
        //           const response = await axios.post( 
        //             "https://hirexweb.onrender.com/full_interview",
        //             {
        //               prompt__:
        //                 `BE EXTREMELY FAST TO RESPOND, TAKE NO MORE THAN 10seconds to give me an answer :\n\n

        // CONTEXT OF THE QUERY : You are a ` +
        //                 "interviewer" +
        //                 ` interviewer in the company ` +
        //                 "P&G" +
        //                 ` so be critical, perspicacious and analytical and don't let the applicant fool you and be harsh and direct in your feedback unless the response is extremly good . \n\n
        //    ACTION REQUIRED : Given your roleplay and the all the information given to you, GIVE ME your FEEDBACK in ` +
        //                 "English" +
        //                 `  on the response of the applicant on this specific question : \n\n Question : \n` +
        //                 onequestion +
        //                 `\n\n Applicant's Answer :\n ` +
        //                 transcription,
           
        //             },
        //             {
        //               headers: {
        //                 "Content-Type": "application/json",
        //               },
        //             }
        //           );

       


            //       //setFeedbackText(response.data.text);
            //     } catch (error) {
            //       console.log(error);
            //     }
            //     setResponseWaiting(false);
            //     //setIsFeedbackTime(true);
            //   }}
            // >
            //   Get Feedback
            // </button>*/}
            <div></div>
          </div>

          <div style={{backgroundColor:"white"}}>
            <ul className="center-list">
              {listAudios.map((audioUrl, index) => (
                <li key={index}>
                  <div
                    className={`audioCard ${
                      isSelected === index ? "selected" : ""
                    }`}
                  >
                    <div className={"rec"}>
                      <p>Recording {index}:</p>
                    </div>
                    <div className={"rec8"}>
                      <audio controls>
                        <source src={audioUrl} type="audio/wav" />
                        Your browser does not support the audio element.
                      </audio>
                    </div>
                    <div className={"rec8"}>
                      <button
                        className={`select-button`}
                        onClick={() => {
                          setIsSelected(index);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          size="xl"
                          style={{ color: isSelected === index ? "green" : "" }}
                        />
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <ToastContainer />
            {responseWaiting && (
              <div className="loading-overlay">
                <div className="loading-spinner"></div>
              </div>
            )}
          </div>
        
    </div>
  );
};

export default RecordingPage;
