import React, { useState, useEffect } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
//import { useNavigate, useLocation } from "react-router-dom";
import RecordingPage from "./RecordingPage";
import "./RecordingFeedback.css"
import {  useNavigate,useLocation  } from 'react-router-dom';

 

const RecordingFeedback = () => {
	const [qindex, setQindex] = useState(0);
	
	const location = useLocation();
	const email = location.state.email
	const questions = location.state.questions
	


	console.log("email is " ,email)
	console.log("Questions: ", questions)
	// const questions = ["Tell us about your experience as a Family Law Expert?",
	// "Can you share your experience in handling cases involving divorce, child custody, and spousal support?",
	// "How do you manage client expectations, especially when outcomes may be uncertain or emotionally charged?"]
 	return (
		 
		
		 
				<>
				{qindex !== questions.length ? (
					<RecordingPage
					onequestion={questions[qindex]}
					qindex={qindex}
					setQindex={setQindex}
					email = {email}
					// jD={jD}
					// cv={cv}
					// bg={bg}
					// companyName={companyName}
					// language={language}
					/>
					) : (
						<div>
						<h1 style={{color:"white"}}>Thank you for your time.</h1>
						{/* <button>General Feedback</button> */}
						</div>
						)}
						</>
					 
						);
					};
					
					export default RecordingFeedback;
					