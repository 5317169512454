import React, { useState } from 'react';
import './PositionSubmission.css'; 
import '../index.css';
import "../firebase";
import {  useNavigate  } from 'react-router-dom';
import { getFirestore, addDoc, collection } from 'firebase/firestore';
//import Alert from '@mui/material/Alert';

 import thresh from './Assets/thresh.png'
import listcheck from './Assets/listcheck.png'
import edit from './Assets/edit.png'
import useradd from './Assets/useradd.png'
import metrics_ from './Assets/metrics.png'
 
const PositionSubmission = () => {
    const db = getFirestore();
    const navigate = useNavigate();

    const [position, setPosition] = useState("");
    const handleInputPosition = (event) => {
        setPosition(event.target.value);
    };

    const [jd, setJd] = useState("");
    const handleInputJd = (event) => {
        setJd(event.target.value);
    };


    const [criterias, setCriterias] = useState("");
    const handleInputCriterias = (event) => {
        setCriterias(event.target.value);
    };


    const [metrics, setMetrics] = useState("");
    const handleInputMetrics = (event) => {
        setMetrics(event.target.value);
    };


    const [threshold, setThreshold] = useState("");
    const handleInputThreshold = (event) => {
        setThreshold(event.target.value);
    };

    const saveDataToFirestore = async (c,j,m,t,p)=> {
        try{
        const docRef = await addDoc(collection(db, "Positions"),{
            Criterias : c,
            JobDesc : j,
            Metrics: m,
            Threshold : t,
            Position: p,
            State:"On"
        })
        
        alert("Position saved successfully!");
        navigate('/');
            }
            catch(error){
                alert("Position not saved, try again!");
                
            }


}

    return (
        <div className='container'>
            <div className = "login-header">
                <div className="text">New Position</div>
                <div className="underline"></div>
            </div>
            <div className="inputs">
            
                <div className="input"> 
                    <img src={useradd} alt='' /> 
                    <input type='text' placeholder='Position'  value={position} 
            onChange={handleInputPosition}  />
                </div>

                <div className="input">
                    <img src={edit} alt='' />
                    <input type='text' placeholder='Job Description'  value={jd} 
            onChange={handleInputJd} />
                </div>
                <div className="input">
                    <img src={listcheck} alt='' />
                    <input type='text' placeholder='Criterias'   value={criterias} 
            onChange={handleInputCriterias} />
                </div>
                <div className="input">
                    <img src={metrics_} alt='' />
                    <input type='text' placeholder='Metrics'  value={metrics} 
            onChange={handleInputMetrics} />
                </div>
                <div className="input">
                    <img src={thresh} alt='' />
                    <input type='text' placeholder='Threshold'  value={threshold} 
            onChange={handleInputThreshold} />
                </div>

            </div>
            <div className='submit-container'>
                <div className={"submit"} onClick={()=>{saveDataToFirestore(criterias,jd,metrics,threshold,position)}}>Save</div>
            </div>
        </div>
    )
}

export default PositionSubmission