import React from "react";
import { BrowserRouter as Router, Route, Routes, Switch } from "react-router-dom";
import Home from "./pages/Home";
import RecordingPage from "./pages/RecordingPage";
import LocalFeedback from "./pages/LocalFeedback";
import RecordingFeedback from "./pages/RecordingFeedback";
import Triage from "./pages/Triage";
import PositionSubmission from "./pages/PositionSubmission"
import Dashboard from "./pages/Dashboard"
import PositionDetail from "./pages/PositionDetail"
import EmailSubmission from "./pages/EmailSubmission"
import PerformanceDashboard from "./pages/PerformanceDashboard"
import LandingPage from "./pages/LandingPage"
import SubscribeNewsletter from "./pages/SubscribeNewsletter"



const App = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/subscribenewsletter" exact element={<SubscribeNewsletter />}  />
          <Route path="/landingpage" exact element={<LandingPage />}  />
          <Route path="/" exact element={<Dashboard />} />
          <Route path="/submission" exact element={<PositionSubmission />} />
          <Route path="/detail" exact element={<PositionDetail />} />
          <Route path="/triage" exact element={<Triage />} />
          <Route path="/email" exact element={<EmailSubmission />} />
          <Route
            path="/recordingfeedback"
            exact
            element={<RecordingFeedback />}
          />
          <Route
            path="/Performance"
            exact
            element={<PerformanceDashboard />}
          />




          {/* <Route path="/" exact element={<Home />} /> */}
          {/* <Route path="/recording" exact element={<RecordingFeedback />} /> */}

          {/* <Route path="/feedback" exact element={<LocalFeedback />} />  */}

        </Routes>
      </Router>
    </div>
  );
};

export default App;
